import React, { Component } from 'react'
import Modal from 'react-modal'
import { Global } from '@emotion/core'

import { globalForModal, modalStyles } from '../styles'

Modal.defaultStyles.overlay.backgroundColor = 'rgba(245, 247, 248, 1)'
Modal.setAppElement('#___gatsby')

export default class ContactModal extends Component {
  handleCloseModal = () => {
    this.props.onClose && this.props.onClose()
  }

  renderSuccess = () => {
    return (
      <React.Fragment>
        <h1 data-testid="contact-form-modal">
          <span />
        </h1>
        <p>Thank you. Your form has been sent</p>
      </React.Fragment>
    )
  }

  renderError = e => {
    return (
      <React.Fragment>
        <h1 data-testid="contact-form-modal">
          <span>!</span>
        </h1>
        <p className="Oops">Oops! Your form did not submit</p>
        <ul>
          <li>{e}</li>
        </ul>
        <p className="buttonBack">
          <button data-testid="contact-close-btn" onClick={() => this.props.onClose()}>← Go Back</button>
        </p>
      </React.Fragment>
    )
  }

  render() {
    const { error } = this.props
    return (
      <React.Fragment>
        {this.props.isOpen ? <Global styles={globalForModal} /> : null}
        <Modal
          shouldCloseOnEsc
          onRequestClose={this.props.onClose}
          isOpen={this.props.isOpen}
          css={modalStyles(error)}
        >
          {error ? this.renderError(error) : this.renderSuccess()}
        </Modal>
      </React.Fragment>
    )
  }
}
